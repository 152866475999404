@import url(https://fonts.cdnfonts.com/css/gotham);

.Container {
  margin-top: 100px;
  padding-left: 15px;
  padding-right: 15px;
}
.footer {
  background-color: #dc744d;
  padding-bottom: 20px;
  padding-top: 30px;
  color: white;
  font-family: "Gotham", sans-serif;
}
.info-container {
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Gotham", sans-serif;
  color: #333333;
}
.grecaptcha-badge {
  bottom: 50px !important;
}
:root {
  --sds-c-icon-color-background: #ed6d41;
  --white: #ffffff;
}
span.slds-icon_container.slds-input__icon.slds-input__icon_right {
  background-color: white;
}
.dropzone {
  height: 200px;
  border: 3px dashed rgba(58, 105, 164, 0.6);
  text-align: center;
  vertical-align: middle;
  line-height: 200px;
}
.slds-card {
  position: inherit;
}
html {
  background: #f8f8f8;
}
.slds-global-header {
  height: 5rem;
}
span.slds-icon_container.slds-pill__remove {
  background-color: transparent;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.login-button {
  margin-right: 100px;
  border-color: #ed6d41;
  color: #ed6d41 !important;
}
@media only screen and (max-width: 800px) {
  .hide-mobile {
    display: none;
  }
  span.slds-radio_faux {
    padding-left: 0.1em !important;
    padding-right: 0.1rem !important;
  }
}
@media only screen and (min-width: 800px) {
  .only-mobile {
    display: none;
  }
}
label.slds-radio_button__label {
  min-width: 60px;
  text-align: center;
  min-height: 40px;
  vertical-align: middle;
}

